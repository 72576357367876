<template>
  <div>
    <div
      class="calc-table-modal"
      sm="4"
      v-if="
        sensorField &&
        sensorField.data_type &&
        sensorField.data_type.toLowerCase() == 'number'
      "
    >
      <span
        @click="clickCheckBox(sensorField)"
        class="curserPointer active-link"
        v-b-tooltip.hover.top="$t('tooTip.TableCalculation')"
        >{{ $t("device.ApplyTable") }}</span
      >
    </div>

    <b-modal
      :id="`modal-calculation-table-${sensorField.field}`"
      centered
      size="lg"
      no-close-on-backdrop
      modal-class="modal-calculation-table  no-header-modal table-graph-model"
    >
      <SensorTable
        v-model="value"
        :isViewOnly="isViewOnly"
        @closeCalculationModal="closeCalculationModal"
      />
      <!-- <div class="apply-btn-outer">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          @click="closeCalculationModal"
          :disabled="disabledApply"
          v-if="!isViewOnly"
        >
          {{ $t("device.Apply") }}
        </b-button>
        <span style="display: none">
          {{ checkCalculations() }} {{ calTable }}</span
        >
      </div> -->
    </b-modal>
  </div>
</template>

<script>
import { BButton, BCardText, VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import SensorTable from "./SensorTable.vue";
// import CalculationTable from "./CalculationTable.vue";

export default {
  name: "CalculationTableModal",
  components: {
    BButton,
    BCardText,
    VBTooltip,
    // CalculationTable,

    SensorTable
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  computed: {},
  data() {
    return {
      rows: [],
      calTable: [],
      showModal: false,
      disabledApply: true,
      radioButton: null,
      rowsArray: [],
      rangesArray: []
    };
  },
  // watch: {
  //   calTable(old, newVal) {
  //     console.log(old);
  //     // this.checkCalculations();
  //   }
  // },
  mounted() {},
  props: ["value", "sensorField", "sensorInfo", "isViewOnly"],

  methods: {
    radioButtonChanged(radio) {
      this.radioButton = radio;
    },
    handledFlagChanged(newFlag) {
      this.disabledApply = newFlag;
    },
    rowsChanges(rows) {
      this.rowsArray = rows;
    },
    rangesChanges(input) {
      if (
        (input.table && !input.table.length) ||
        (input.table && this.validateInput(input.table))
      ) {
        this.rangesArray = input;
      }
    },
    validateInput(inputArray) {
      return inputArray.every((item) => {
        return (
          item.x !== null &&
          item.x !== "" &&
          item.x !== undefined &&
          item.a !== null &&
          item.a !== "" &&
          item.a !== undefined &&
          item.b !== null &&
          item.b !== "" &&
          item.b !== undefined
        );
      });
    },
    checkCalculations() {
      const test = this.calTable;
      const testLength = this.calTable.length;
      const result =
        this.calTable != [] &&
        !!this.calTable.length &&
        !!this.calTable.find(
          (e) => e.invalid == true || e.x === "" || e.y === ""
        );
      // this.disabledApply = result;
      return result;
    },
    closeCalculationModal(rowsArray) {
      // if (this.radioButton === "XYPair") {
      this.$emit("input", rowsArray);
      //   this.calTable = this.rowsArray;
      this.value = rowsArray;
      // } else {
      //   this.calTable = this.rangesArray;
      //   this.value = this.rangesArray;
      //   this.$emit("input", this.rangesArray);
      // }
      this.showModal = false;

      this.$bvModal.hide(`modal-calculation-table-${this.sensorField.field}`);
      //  this.checkBoxEnabled();
    },
    clickCheckBox(table) {
      this.calTable = this.value || [];
      this.showModal = true;

      this.$bvModal.show(`modal-calculation-table-${this.sensorField.field}`);
    }
  }
};
</script>
<style lang="scss">
.active-link {
  color: var(--primary);
  text-decoration: none;
}
.table-graph-model .modal-dialog {
  width: 1012px;
  max-width: 1012px;
}
.apply-btn-outer {
  display: flex;
  justify-content: center;
}
</style>
